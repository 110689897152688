import { OverlayRef } from '@angular/cdk/overlay';
import {
    NgClass,
    NgComponentOutlet,
    NgTemplateOutlet,
} from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    TemplateRef,
    Type,
} from '@angular/core';

import { ModalRef } from '../../services/modal/modal-ref';
import { IconComponent } from '../icon/icon.component';

@Component({
    selector: 'eb-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        IconComponent,
        NgClass,
        NgTemplateOutlet,
        NgComponentOutlet,
    ],
})
export class ModalComponent implements OnInit {
    public noPadding = !this.ref.config.padding;

    public hasBorder = this.ref.config.hasBorder;

    public closable = this.ref.config.closable;

    public contentType: 'template' | 'string' | 'component' = 'string';

    public content: string | TemplateRef<any> | Type<any> = '';

    public get contentString(): string | null {
        if (this.contentType === 'string') {
            return this.content as string;
        }
        return null;
    }

    public get contentTemplateRef(): TemplateRef<any> | null {
        if (this.contentType === 'template') {
            return this.content as TemplateRef<any>;
        }
        return null;
    }

    public get contentComponent(): Type<any> | null {
        if (this.contentType === 'component') {
            return this.content as Type<any>;
        }
        return null;
    }

    public context: {
        close?: Function,
    } = {};

    constructor(
        public ref: ModalRef,
        private overlay: OverlayRef,
    ) {
    }

    setEnableBackdropClick(value: boolean) {
        if (value) {
            this.overlay.backdropElement?.classList.remove('cdk-overlay-backdrop-disabled');
        } else {
            this.overlay.backdropElement?.classList.add('cdk-overlay-backdrop-disabled');
        }
    }

    setClosable(closable: boolean) {
        this.closable = closable;
    }

    close() {
        this.ref.close(null);
    }

    ngOnInit() {
        this.content = this.ref.content;

        if (typeof this.content === 'string') {
            this.contentType = 'string';
        } else if (this.content instanceof TemplateRef) {
            this.contentType = 'template';
            this.context = {
                close: this.ref.close.bind(this.ref),
            };
        } else {
            this.contentType = 'component';
        }
    }
}
