
import { OverlayRef } from '@angular/cdk/overlay';
import {
    TemplateRef,
    Type,
} from '@angular/core';
import {
    finalize,
    Observable,
    Subject,
} from 'rxjs';

// R = Response Data Type, T = Data passed to Modal Type
export class ModalRef<R = any, T = any> {
    private afterClosedInternal$ = new Subject<R | undefined>();

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public afterClosed$: Observable<R | undefined> = this.afterClosedInternal$.pipe(
        finalize(() => {
            this.overlay.dispose();
        }),
    );

    public constructor(
        public overlay: OverlayRef,
        public content: string | TemplateRef<any> | Type<any>,
        public data: T,
        public config: {
            padding: boolean,
            hasBorder?: boolean,
            isOutsideClickCloseModal?: boolean,
            closable?: boolean,
        } = {
            padding: true,
            hasBorder: false,
            isOutsideClickCloseModal: true,
            closable: true,
        },
    ) {
        if (config?.isOutsideClickCloseModal ?? true) {
            this.overlay
                .backdropClick()
                .subscribe(() => {
                    this.closeModal();
                });
        }
    }

    public close(data: R) {
        this.closeModal(data);
    }

    public dispose() {
        this.overlay.dispose();
    }

    private closeModal(data?: R) {
        this.overlay.dispose();
        this.afterClosedInternal$.next(data);
    }
}
