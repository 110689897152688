<div
    ebAuthorisation
    class="wrapper"
    [authorisationPermissions]="authorisationPermissions"
    (click)="onClick()"
    cursorStyle="default"
>
    <label
        class="checkbox-input"
        [ngClass]="{ 'checkbox-input--unauthorised': !isAuthorised }"
    >
        <eb-icon
            [class.disabled]="disabled"
            [color]="isAuthorised ? 'primary' : 'gray'"
            [hidden]="!(value === true && indeterminate === false)"
            icon="ui/form/checkboxfull"
        />
        <eb-icon
            [class.disabled]="disabled"
            [color]="isAuthorised ? 'primary' : 'gray'"
            [hidden]="!(value !== true)"
            icon="ui/form/checkboxempty"
        />
        <eb-icon
            [class.disabled]="disabled"
            [color]="isAuthorised ? 'primary' : 'gray'"
            [hidden]="!(value === true && indeterminate === true)"
            icon="ui/form/checkboxindeterminate"
        />
    </label>

    <div class="right">
        <ng-content />
    </div>
</div>
