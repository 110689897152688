import { NgClass } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    Output,
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

import { ResumeFeatureContext } from '~/app/shared/types/resume-feature-context.type';

import { AuthorisationDirective } from '../../directives/authorisation/authorisation.directive';
import { BasePermissionsService } from '../../services/base-permissions/base-permissions.service';
import { IconComponent } from '../icon/icon.component';

export type CheckboxInputValue = boolean | undefined;

@Component({
    selector: 'eb-checkbox-input',
    templateUrl: './checkbox-input.component.html',
    styleUrls: ['./checkbox-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        // eslint-disable-next-line no-use-before-define
        useExisting: forwardRef(() => CheckboxInputComponent),
        multi: true,
    }],
    standalone: true,
    imports: [
        AuthorisationDirective,
        NgClass,
        IconComponent,
    ],
})
export class CheckboxInputComponent implements ControlValueAccessor {
    @Input()
    public disabled: boolean = false;

    @Input()
    public indeterminate: boolean = false;

    @Input()
    public isAuthorised: boolean = true;

    @Input()
    public resumeFeatureContext?: ResumeFeatureContext;

    @Input()
    public authorisationPermissions: string[] = [];

    @Output()
    public checkChange = new EventEmitter<CheckboxInputValue>();

    public value: CheckboxInputValue = undefined;

    constructor(
        private cd: ChangeDetectorRef,
        private permissionsService: BasePermissionsService,
    ) {
    }

    public writeValue(value: CheckboxInputValue): void {
        this.value = value;
        this.cd.detectChanges();
    }

    public registerOnChange(fn: (args: CheckboxInputValue) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public onChange = (_args: CheckboxInputValue) => {
    };

    public onTouched = () => {
    };

    public onClick() {
        const hasPermissions = this.permissionsService.hasPermissions(this.authorisationPermissions);
        if (this.disabled || !hasPermissions) return;
        this.value = this.next();
        this.onChange(this.value);
        this.checkChange.next(this.value);
    }

    private next(): CheckboxInputValue {
        switch (this.value) {
            case true:
                return false;
            default:
                return true;
        }
    }
}
