import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core';

@Component({
    selector: 'eb-dropdown-panel-body',
    templateUrl: './dropdown-panel-body.component.html',
    styleUrls: ['./dropdown-panel-body.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class DropdownPanelBodyComponent {
}
