import {
    Overlay,
    OverlayConfig,
    OverlayRef,
    PositionStrategy,
    ScrollStrategy,
} from '@angular/cdk/overlay';
import {
    ComponentPortal,
} from '@angular/cdk/portal';
import {
    Injectable,
    Injector,
    TemplateRef,
    Type,
} from '@angular/core';

import { ModalRef } from './modal-ref';
import { ModalComponent } from '../../components/modal/modal.component';

interface FormModalConfig {
    panelClass?: string,
    hasBackdrop?: boolean,
    backdropClass?: string,
    positionStrategy?: PositionStrategy,
    scrollStrategy?: ScrollStrategy,
}

const DEFAULT_CONFIG: FormModalConfig = {
    hasBackdrop: true,
    backdropClass: 'cdk-overlay-dark-backdrop',
    panelClass: 'eb-modal-panel',
};

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    constructor(
        private overlay: Overlay,
        private injector: Injector,
    ) {
        // dependency injection
    }

    public open<R = any, T = any>(
        content: string | TemplateRef<any> | Type<any>,
        data: T,
        config: {
            padding: boolean,
            hasBorder?: boolean,
            isOutsideClickCloseModal?: boolean,
            closable?: boolean,
        } = {
            padding: true,
            hasBorder: false,
            isOutsideClickCloseModal: true,
            closable: true,
        },
    ): ModalRef<R> {
        const positionStrategy = this.overlay
            .position()
            .global()
            .centerHorizontally()
            .centerVertically();

        const scrollStrategy = this.overlay
            .scrollStrategies
            .block();

        const configs = new OverlayConfig({
            ...DEFAULT_CONFIG,
            positionStrategy,
            scrollStrategy,
        });

        const overlayRef = this.overlay.create(configs);

        const modalRef = new ModalRef<R, T>(overlayRef, content, data, {
            ...config,
            closable: config.closable === undefined ? true : config.closable,
        });

        const injector = this.createInjector(modalRef, overlayRef, this.injector);
        overlayRef.attach(new ComponentPortal(ModalComponent, null, injector));

        return modalRef;
    }

    createInjector(modalRef: ModalRef, overlayRef: OverlayRef, inj: Injector) {
        return Injector.create({
            parent: inj,
            providers: [
                {
                    provide: ModalRef,
                    useValue: modalRef,
                },
                {
                    provide: OverlayRef,
                    useValue: overlayRef,
                },
            ],
        });
    }
}
