@if (closable) {
    <div class="modal__close-wrapper">
        @if (ref.config.isOutsideClickCloseModal) {
            <eb-icon
                class="modal__body-close"
                (click)="close()"
                icon="ui/action/symbols/close"
                width="55"
            />
        }
    </div>
}
<div
    class="modal__wrapper"
    [ngClass]="{
        'modal__wrapper--no-padding': noPadding,
        'modal__wrapper--border': hasBorder
    }"
>
    <div class="modal__body">
        <ng-content />
        @switch (contentType) {
            @case ('string') {
                @if (contentString) {
                    <div [innerHTML]="contentString"></div>
                }
            }
            @case ('template') {
                @if (contentTemplateRef) {
                    <ng-container *ngTemplateOutlet="contentTemplateRef; context: context" />
                }
            }
            @case ('component') {
                @if (contentComponent) {
                    <ng-container *ngComponentOutlet="contentComponent" />
                }
            }
        }
    </div>
</div>
