import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
} from '@angular/core';

@Component({
    selector: 'eb-dropdown-panel-alt',
    templateUrl: './dropdown-panel-alt.component.html',
    styleUrls: ['./dropdown-panel-alt.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class DropdownPanelAltComponent {
    @HostBinding('class.dropdown-panel-alt--radius')
    @Input()
    public radius: boolean = false;

    @HostBinding('class.dropdown-panel-alt--with-notch')
    @Input()
    public withNotch: boolean = true;

    @HostBinding('class.dropdown-panel-alt--with-overflow')
    @Input()
    public withOverflow: boolean = false;

    @HostBinding('class.dropdown-panel-alt--with-border')
    @Input()
    public withBorder: boolean = true;

    @Output() closed = new EventEmitter<void>();
}
